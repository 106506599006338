import * as types from '../config/actionType'



export const loadParamUserRequestAction = () => {
    return (
        {
            type: types.LOAD_PARAM_USER_REQ
        }
    )
}

export const loadParamUserErrorAction = (error) => {
    return (
        {
            type: types.LOAD_PARAM_USER_REQ_ERROR,
            error
        }
    )
}

export const loadParamUserFinishAction = () => {
    return (
        {
            type: types.LOAD_PARAM_USER_REQ_FINISH
        }
    )
}



export const loadParamUserAction = (data) => {
    return (
        {
            type: types.LOAD_PARAM_USER,
            data
        }
    )
}

export const loadParamForCurrentUserAction = () => {
    return (
        {
            type: types.LOAD_PARAM_FOR_CURRENT_USER
        }
    )
}


export const updateParamUserForCustomerAction = (param) => {
    return (
        {
            type: types.UPDATE_PARAM_USER,
            param
        }
    )
}


export const updateParamCurrentUserForCustomerAction = (param) => {
    return (
        {
            type: types.UPDATE_PARAM_CURRENT_USER,
            param
        }
    )
}


export const loadedParamAction = (param) => {
    return (
        {
            type: types.PARAM_USER_LOADED,
            param
        }
    )
}


export const loadVehiculeSelectedAction = (vehicule) => {
    return (
        {
            type: types.LOAD_VEHICULE_SELECTED,
            vehiculeSelected: vehicule
        }
    )
}


export const deleteVehiculeAction = (vehicule) => {
    
    return (
        {
            type: types.DELETE_VEHICULES,
            vehicule
        }
    )
}


export const addVehiculeAction = (vehicule) => {
    
    return (
        {
            type: types.ADD_VEHICULES,
            vehicule
        }
    )
}



export const upadateVehiculeAction = (vehicule) => {
    
    return (
        {
            type: types.UPDATE_VEHICULES,
            vehicule
        }
    )
}