
import axios from 'axios'
import {  listePostionVehiculeURL } from '../config/api'
import { encryptStorage } from 'components/EncryptedStorage/encryptedStorage';



export const  getPositionVoiture = async () => 
{
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
    const email =  await encryptStorage.getItem('userInfo').userName;

    const dataToSend = {email: email}

       return axios.post(listePostionVehiculeURL , dataToSend, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}
  