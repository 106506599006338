import { connect } from "react-redux";
import React, { useCallback } from "react";
import * as typeAction from '../../../action/mapsAction'
import { useEffect } from "react";
import { useState } from "react";
import { Marker, InfoWindow, InfoBox } from '@react-google-maps/api';
import moment from 'moment';

import { AuthContext } from '../../../context/authentificationContext';


const divStyle = {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15
  }
  

const MarkerVehicule = (props) => 
{

  const { signOut } = React.useContext(AuthContext);
  const [comptErr, setComptErr] = useState(0)

  useEffect(() => {
  
    setActiveMarker(null)
  }, [props.mapClicked])


 
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    
    
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

    const getImageStatus = (lastTime, status, styleCus) => 
    {
      const on = require("../../../assets/img/etatvehicule/rondvert.png")
      const off = require("../../../assets/img/etatvehicule/rondrouge.png")
      const disconect = require("../../../assets/img/etatvehicule/rondcroix.png")
    
      const curDate = moment(lastTime, "DD-MM-YYYY HH:mm:ss");
      const curDatePlus1H = moment().subtract(30, 'm')
    
      if (curDate < curDatePlus1H)
      {
    
        return (
                disconect
            )
      }
      else
      {
        return (
            status === true ? on : off
       
        )
      }
    
      
      
    }

    const [positionTab, setPositionTab] = useState([]);
    //const motoImg = require("../../assets/img/vehiculeImage/moto.png")
    //const autoImg = require("../../assets/img/vehiculeImage/auto.png")

    

    const listMarker = () => 
    {
        
        const loopMarker = positionTab.map((position) => 
        {
          /*
            const handleOffMouseOver = () => {
                infowindow.close();
            }

            const handleOnMouseOver = () => {
                infowindow.open({
                   
                    map : props.map,
                    shouldFocus: false,
                    
                  });
                  
            }
/*
            const infowindow = new window.google.maps.InfoWindow(
                {
                    
                    content: '<b>' + position.numeroImmatriculation + '</b><p><br></p><p><b>Marque:</b> ' + position.marque + '</p><p><b>Model :</b> ' + position.modele + '</p><p><b>Etat vehicule :</b> ' + (position.enMarche === true ? 'Allumer' : 'Eteint') + '</p><p><b>Vitesse :</b> ' + position.vitesseKM + 'km/h</p><p><b>Mise a jour :</b> ' + position.dateMiseAjourDevice + '</p>',
                    position : {
                    lat: Number(position.latitude), lng: Number(position.longitude),
                    
                }
                
              });
*/
            
              const contentInfoBox = <div> 
                <b>  {position.numeroImmatriculation}  </b> 
                <p> </p> <p> 
                  <b>Marque:</b> { position.marque } 
                  </p><p><b>Model :</b> { position.modele } 
                  </p><p><b>Etat vehicule : </b>  { position.enMarche === true ? 'Allumer' : 'Eteint' } 
                  </p><p><b>Vitesse :</b> { position.vitesseKM } km/h 
                  </p><p><b> Mise a jour :</b> { position.dateMiseAjourDevice} </p> 
                  </div>

            //console.log('position = ',position)
            if ((position.photoVehicule === "") || (position.photoVehicule === null))
            {
              
                return (
                    <div key={"div_vehicule_" + position.numeroImmatriculation}>
                    <Marker
                    //onClick={handleOnMouseOver}
                    onClick={() => handleActiveMarker("vehicule_" + position.numeroImmatriculation)}
                    key={"vehicule_" + position.numeroImmatriculation} 
                    position={{lat: Number(position.latitude), lng: Number(position.longitude) }}
                    icon={{
    
                        url: (position.typeVehicule === 'Voiture') ? 'https://www.sentracking.com/photmat/auto.png' : 'https://www.sentracking.com/photmat/moto.png',
                        anchor: new window.google.maps.Point(17, 46),
                        scaledSize: new window.google.maps.Size(37, 37)
                
                    }}
                     >
                        {activeMarker === "vehicule_" + position.numeroImmatriculation ? (
                         <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            {contentInfoBox}
                        </InfoWindow>
                        ) : null}

                     </Marker>

                     <Marker 
                    
                    position={{lat: Number(position.latitude), lng: Number(position.longitude) }}
                    icon={{
    
                        url: getImageStatus(position.dateMiseAjourDevice, position.enMarche),
                        anchor: new window.google.maps.Point(5, 56),
                        scaledSize: new window.google.maps.Size(15, 15)
                
                    }}
                    
                     />
                     </div>

                     
                )
            }
            else
            {
              //console.log ("position <MarkerVehicule> = ", position)
                return (
                    <div key={"div_vehicule_" + position.numeroImmatriculation}>
                         
                    <Marker 
                   
                    //onClick={handleOnMouseOver}
                    //onMouseOut={handleOffMouseOver}
                    onClick={() => handleActiveMarker("vehicule_" + position.numeroImmatriculation)}
                    key={"vehicule_" + position.numeroImmatriculation} 
                    position={{lat: Number(position.latitude), lng: Number(position.longitude) }}
                    icon={{
    
                        url: 'https://www.sentracking.com/photmat/' + position.photoVehicule + ".png",
                        anchor: new window.google.maps.Point(17, 46),
                        scaledSize: new window.google.maps.Size(37, 37)
                
                    }}
                     >
                       {activeMarker === "vehicule_" + position.numeroImmatriculation ? (
                         <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            {contentInfoBox}
                        </InfoWindow>
                        ) : null}

                    </Marker>

                    <Marker 
                    
                    position={{lat: Number(position.latitude), lng: Number(position.longitude) }}
                    icon={{
    
                        url: getImageStatus(position.dateMiseAjourDevice, position.enMarche),
                        anchor: new window.google.maps.Point(5, 56),
                        scaledSize: new window.google.maps.Size(15, 15)
                
                    }}
                    
                     />

                    
                     </div>
                )
            }
            
        })
        if (positionTab.length > 0)
        {
            return (loopMarker)
        }
        else
        {
            return (
                <>
                </>
            )
        }
      }


      useEffect(() => 
      {
        
        const markerError = props.loadMarker.error.toString();
        if (markerError !== "")
          {
            

            if (markerError.includes("401") === true)
            {
              setComptErr(comptErr + 1)
            //  console.log("ERREUR = ", props.loadMarker.error.toString())
            }
            if (comptErr > 10)
            {
              signOut();
              setComptErr(0);
            }
          }
    
        
      }, [props.loadMarker])

      useEffect(() => {
       // console.log("comptErr = ", comptErr)
      }, [comptErr])

  useEffect(() => 
  {
    //console.log(props.loadMarker)

    setPositionTab(props.loadMarker.markers);
  }, [props.loadMarker.markers])
    
    useEffect(() => 
    {
    const updateMarkerLoop = setInterval(() => {
        props.loadMarkerDispatch() 
    }, 3000);
    return () => clearInterval(updateMarkerLoop);
    }, [])

    useEffect(() => {
        //console.log('position TAB', positionTab)
    }, [positionTab])


    return (
        <>
            {listMarker()}
        </>
    )

}

const mapsStateToProps = (state) =>
{
    //console.log('LOAD MARKER', state.mapMarkers)
  return(
    {
      loadMarker: state.mapMarkers,
     // loadMarkerError: state.mapMarkers.error.toString()
    }
  )
  
}

const mapsDispatchToProps = (dispatch) =>
{
  //temp

  //const id = {identifiantClient: 1}
  return {
    loadMarkerDispatch: () => dispatch(typeAction.loadMarkersAction())
  }
}


export default connect(mapsStateToProps, mapsDispatchToProps)(MarkerVehicule)