import * as types from '../config/actionType'



export const loadUserRequestAction = () => {
    return (
        {
            type: types.LOAD_USER_REQ
        }
    )
}

export const loadUserErrorAction = (error) => {
    return (
        {
            type: types.LOAD_USER_ERROR,
            error
        }
    )
}

export const loadUserFinishAction = () => {
    return (
        {
            type: types.LOAD_USER_FINISH
        }
    )
}



export const loadUserAction = (client) => {
    return (
        {
            type: types.LOAD_USERS,
            client
        }
    )
}


export const userLoadedAction = (users) => {
    return (
        {
            type: types.USERS_LOADED,
            users: users
        }
    )
}

export const deleteUserAction = (user) => {
   
    return (
        {
            type: types.DELETE_USERS,
            user
        }
    )
}


export const addUserAction = (user) => {
    
    return (
        {
            type: types.ADD_USERS,
            user
        }
    )
}



export const upadateUserAction = (user) => {
    
    return (
        {
            type: types.UPDATE_USERS,
            user
        }
    )
}