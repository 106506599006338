import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Icon from "@material-ui/core/Icon";
import { changePassword } from '../../services/passwordService';
// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";
import * as notif from "components/Notification/notification"
import InputAdornment from "@material-ui/core/InputAdornment";
import { useHistory } from "react-router";
//import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import avatar from "assets/img/faces/marc.jpg";
import { useNavigate } from "react-router-dom-v5-compat";

const useStyles = makeStyles(styles);

export default function UserProfile() 
{

  const history = useNavigate();

  const [password, setPassword] = useState("")
  const [passwordState, setPasswordState] = useState("")

  const [passwordNew, setPasswordNew] = useState("")
  const [passwordNewState, setPasswordNewState] = useState("")

  const [passwordNewBis, setPasswordBisNew] = useState("")
  const [passwordNewBisState, setPasswordNewBisState] = useState("")


  const classes = useStyles();


  const verifyLength = (value, length) => {
    if (value.length > length) {
      return true;
    }
    return false;
  };

  const handleValidButton = () => {
    if (password === "")
    {
      setPasswordState("error");
    }
    if (passwordNew === "")
    {
      setPasswordNewState("error");
    }
    if (passwordNewBis === "")
    {
      setPasswordNewBisState("error");
    }
    updatePasswordHandle();
  }


  const updatePasswordHandle =  () => 
    {
      let title = "";
      let msg = "";

        if ( password.length == 0 || passwordNew.length == 0  || passwordNewBis.length == 0) 
        {
          title = "Saisie Incorecte !"
          msg = "Le champ du mot de passe doit avoir une valeur ";
        
          notif.showFailedNotification(title, msg)
          return;
        }

        if ( passwordNew.localeCompare(passwordNewBis)  != 0)
        {
          title = "Password Incorrecte!"
          msg = "Les deux mots de passes sont diffenrents.";
          notif.showFailedNotification(title, msg)
            return;
        }
     
        
              changePassword(password, passwordNew, passwordNewBis).then(
                (res) => {
                    
                    setPassword("");
                    setPasswordNew("");
                    setPasswordBisNew("");
                    

                    title = "Succes!"
                    msg = "Mot de passe Changé avec succee";
                    notif.showSuccessNotification(title, msg)
                    //history.back();
                    history.goBack();
    

                }
             ).catch((err) => {
              title = "Password Incorrecte!"
              msg = "Verifier l'ancien mot de passe";
              notif.showFailedNotification(title, msg)
             // console.log('Erreur getAuthentificationData verifier mot de passe', err)
             })
    }

  return (
    
      
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Modification du Mot de passe - 
              </h4>
            </CardHeader>
            <CardBody>
            <form>
             
                <CustomInput
                success={passwordState === "success"}
                error={passwordState === "error"}
                  labelText="Mot de passe"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                        if (verifyLength(event.target.value, 4)) {
                          setPasswordState("success");
                        } else {
                          setPasswordState("error");
                        }
                        setPassword(event.target.value);
                      },
                      
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
               
                <CustomInput
                success={passwordNewState === "success"}
                error={passwordNewState === "error"}
                  labelText="Nouveau Mot de passe"
                  id="password_new"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setPasswordNewState("success");
                        } else {
                          setPasswordNewState("error");
                        }
                        setPasswordNew(event.target.value);
                      },
                      
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                
                <CustomInput
                  success={passwordNewBisState === "success"}
                  error={passwordNewBisState === "error"}
                  labelText="Nouveau mot de passe"
                  id="new_password_bis"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                        if (passwordNew === event.target.value) 
                        {
                          setPasswordNewBisState("success");
                        } else {
                          setPasswordNewBisState("error");
                        }
                        setPasswordBisNew(event.target.value);
                      },
                      
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                
              
              <Button className={classes.registerButton} color="info" onClick={() => {handleValidButton()}} >
                Valider
              </Button>
              
              
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    
  );
}
