import {takeEvery, call, put, all} from 'redux-saga/effects'

import * as types from '../config/actionType'
import * as service from '../services/deviceService'
import * as actions from '../action/deviceAction'

function* loadDevices()
{
    try {
        yield put(actions.loadDeviceRequestAction())
        const devices = yield call(service.getListDevice)
        yield put(actions.deviceLoadedAction(devices))

    } catch (error) 
    {
        yield put(actions.loadDeviceErrorAction(error))
        yield put(actions.deviceLoadedAction([]))
        
    }
    finally
    {
        yield put(actions.loadDeviceFinishAction())
    }
}



function* addDevice(data)
{
    try {
        
        yield put(actions.loadDeviceRequestAction())
        yield call(service.addDeviceService, data)
        //yield put(actions.loadDeviceAction())

    } catch (error) 
    {
        yield put(actions.loadDeviceErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadDeviceFinishAction())
    }
}

function* updateDevice(data)
{
    try {
        
        yield put(actions.loadDeviceRequestAction())
        yield call(service.updateDeviceService, data)
        //yield put(actions.loadDeviceAction())

    } catch (error) 
    {
        yield put(actions.loadDeviceErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadDeviceFinishAction())
    }
}


function* deleteDevice(data)
{
    try {
       
        yield put(actions.loadDeviceRequestAction())
        yield call(service.deleteDeviceService, data)
        yield put(actions.loadDeviceAction())

    } catch (error) 
    {
        yield put(actions.loadDeviceErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadDeviceFinishAction())
    }
}


function* watchLoadDevices()
{
 yield takeEvery(types.LOAD_DEVICES, loadDevices)
}

function* watchAddDevices()
{
 yield takeEvery(types.ADD_DEVICES, addDevice)
}

function* watchUpdateDevices()
{
 yield takeEvery(types.UPDATE_DEVICES, updateDevice)
}

function* watchDeleteDevices()
{
 yield takeEvery(types.DELETE_DEVICES, deleteDevice)
}

export function* deviceSaga() 
{
    yield all ([
        watchLoadDevices(),
        watchAddDevices(),
        watchUpdateDevices(),
        watchDeleteDevices(),
    ])
}