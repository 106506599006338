import rootReducer from 'reducers/rootReducer';
import {createStore, applyMiddleware} from 'redux'
//import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga'
import rootSaga from './saga/rootSaga';

const saga = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(saga/*, logger*/));

saga.run(rootSaga);

export default store;