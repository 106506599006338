import { VEHICULES_LOADED, LOAD_VEHICULE_REQ, LOAD_VEHICULE_FINISH, LOAD_VEHICULE_ERROR, LOAD_VEHICULE_SELECTED } from "../config/actionType";


const initialState = {
    loading: false,
    vehicules: [],
    error: "",
    vehiculeSelected: {
    anneeVehicule: "",
    marque: "",
    modele: "",
    nombreChevaux: "",
    nombreKilometrage: "",
    numeroImmatriculation: "",
    photoVehicule: "",
    typeVehicule: "",
    latitude:  "",
    longitude: ""
    }
};

export const vehiculeReducer = (state = initialState, action) => {
    switch (action.type)
    {
        case VEHICULES_LOADED:
            return (
                {
                    ...state,
                   vehicules: action.vehicules,
                }
            )
            case LOAD_VEHICULE_SELECTED:
                return (
                    {
                        ...state,
                        vehiculeSelected: action.vehiculeSelected,
                    }
                )
            case LOAD_VEHICULE_REQ:
            return (
                {
                   ...state,
                   loading: true,
                   error: "",
                   
                }
            )
            case LOAD_VEHICULE_ERROR:
                return (
                    {
                       ...state,
                       error: "Erreur requete"
                    }
                )
                case LOAD_VEHICULE_FINISH:
                return (
                    {
                       ...state,
                       loading: false,
                    }
                )

        default:
            return state
    }
}
