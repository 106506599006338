import React, { Component } from "react";


import { Marker } from '@react-google-maps/api';
import { connect } from "react-redux";
import * as typeAction from "../../../action/dateDynAction"
//import MapView, { Marker } from "react-native-maps";
//import {StyleSheet} from 'react-native';

//import Animated from 'react-native-reanimated';

class AnimatedMarkersCustum extends Component
{
/** Marker's width */
 MARKER_WIDTH = 70;
/** Marker's height */
 MARKER_HEIGHT = 70; // marker height
/** Customizable anchor prop - Specify your desired anchor adjustements here */
 ANCHOR = { x: 0.5, y: 0.5 };  // in my case I customized this based on marker dimensions like this: { x: 0.5, y: 1 - 10 / MARKER_HEIGHT } lifting the marker up a bit

  constructor(props)
  {
    super(props);
    this.stop = false
    this.speed = 1
    this.pause = false;
    //this.props.controlButPlay(false)

    //this.props.ref = this
    this.state = {
      completed: 0,
      coords: [],
      coordsMarker: {latitude: 14.75071, longitude: -17.45070833 },


    };
  }

/** auto generated centerOffset prop based on the anchor property */

 getCenterOffsetForAnchor = function (anchor, markerWidth, markerHeight) {
  return ({
      x: markerWidth * 0.5 - markerWidth * anchor.x,
      y: markerHeight * 0.5 - markerHeight * anchor.y
  });
};

 CENTEROFFSET = this.getCenterOffsetForAnchor(
  this.ANCHOR,
  this.MARKER_WIDTH,
  this.MARKER_HEIGHT,
);


  componentDidMount() {
    this._animate(this.props.coordinates, 0);
  }



  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.coordinates !== this.props.coordinates) {
      this._animate(nextProps.coordinates, 0);
    }
  }

  shouldComponentUpdate(nextProps, nextState)
  {
    if (nextState.coords.length !== this.state.coords.length)
    {

      return true;
    }
    return false;
  }

setStop()
{
  this.stop = true;
  clearInterval(this.interval);
  //console.log("STOP")
}


/*
getImageFromServer(date, type, filename)
{
  const motoImg = require("../assets/moto.png")
  const autoImg = require("../assets/auto.png")
  let status;
  let imageName;
  let autoMoto = ""



    if (filename === null)
    {
      return (
        <Animated.Image
              source={type === "Voiture" ? autoImg : motoImg}
              style={[styles.marker]}
              resizeMode="stretch"
            ></Animated.Image>
        )
    }
    return (
    <Animated.Image
          source={{uri:'https://www.sentracking.com/photmat/' + filename + ".png"}}
          style={[styles.marker]}
          resizeMode="stretch"
        ></Animated.Image>
    )


}
*/

setPlay()
{
  this.props.controlButPlay(false)
  this.pause = false;
  //console.log("PLAY")
}

setPause()
{
  this.props.controlButPlay(true)
  this.pause = true;
  //console.log("EN PAUSE")

}

setSpeed(speed)
{
  this.speed = speed;
}

componentWillUnmount()
{
  //console.log("UNMOUNTED")
  this.stop = true;
  clearInterval(this.interval);
}

  _animate(allCoords, coordDepard)
  {
    //console.log(allCoords)
   // console.log("ANIMATE")

    const self = this;
    this.stop = false;
    const len = allCoords.length;
    let completed = 0;
    this.state.coords = [];


    
    //const steps = 5//parseInt((allCoords.length / 100), 10);
    clearInterval(this.interval);
    this.interval = setInterval(() =>
    {
      const coords = this.state.coords.slice(0);

      let i = completed;
      while (i < (completed + this.speed) && i <= len &&  this.props.loadTrajetLecteurInfo.playPauseReload !== 1)
      {
        //console.log("playpause = ", this.props.loadTrajetLecteurInfo.playPauseReload)
        if (this.props.loadTrajetLecteurInfo.playPauseReload === 4)
        {
          //completed = len + 1;
          i = len + 1;
          self.setState({ coords: [] });
          clearInterval(self.interval);
        }
          //if (this.pause === false)
          {
            if (allCoords[i])
            {

              coords.push(allCoords[i]);
                //coordsMarker = newCoordinate;
                self.setState({ coordsMarker: {latitude:allCoords[i].latitude, longitude: allCoords[i].longitude} });
                //this.props.coordonateDynamic({latitude:allCoords[i].latitude, longitude: allCoords[i].longitude, cont: i, dateReceptionPosition: allCoords[i].dateReceptionPosition})
                this.props.dateDynDispatch({
                  dateDyn: allCoords[i].dateReceptionPosition,
                  compteur: i,
                  latitude:allCoords[i].latitude,
                  longitude: allCoords[i].longitude,
                  vitesse: allCoords[i].vitesseKM
                })
            }
            i++;
          }
      }
/*
      for (let i = completed; i < (completed + this.speed) && i <= len; i += 1)
      {
        if (this.stop === true)
          {
            //completed = len + 1;
            i = len + 1;
            self.setState({ coords: [] });
            clearInterval(self.interval);

          }
        if (allCoords[i])
        {

          coords.push(allCoords[i]);


            //coordsMarker = newCoordinate;
            self.setState({ coordsMarker: {latitude:allCoords[i].latitude, longitude: allCoords[i].longitude} });

            this.props.coordonateDynamic({latitude:allCoords[i].latitude, longitude: allCoords[i].longitude, cont: i, dateReceptionPosition: allCoords[i].dateReceptionPosition})
            //this.props.displayDate(allCoords[i].dateReceptionPosition)

        }
      }
      */
      self.setState({ coords });
      //self.setState({ coordsMarker });
      if (completed >= len)
      {
        clearInterval(self.interval);
        self.setState({ coords: [] });
      }
      if ((this.props.loadTrajetLecteurInfo.playPauseReload === 0) || (this.props.loadTrajetLecteurInfo.playPauseReload === 3))
      {
        completed += this.speed;
      }

    }, (this.props.interval || 10));
  }

  getImageFromServer()
  {
    let urlPhotoVehicule = "";
    if ((this.props.photoVehicule === "") || (this.props.photoVehicule === null))
    {
        urlPhotoVehicule = (this.props.typeVehicule === 'Voiture') ? 'https://www.sentracking.com/photmat/auto.png' : 'https://www.sentracking.com/photmat/moto.png'
    }
    else
    {
        urlPhotoVehicule = 'https://www.sentracking.com/photmat/' + this.props.photoVehicule + ".png"
    }
    return (urlPhotoVehicule)
  }

  render()
  {
    //console.log(this.state.coordsMarker)
    return (
        <Marker
        {...this.props}
        ref={

          marker => {this.marker = marker}


        }
        position={{lat: this.state.coordsMarker.latitude, lng: this.state.coordsMarker.longitude }}
        //coordinate={this.state.coordsMarker}
        anchor={this.ANCHOR}
        centerOffset={this.CENTEROFFSET}
        url={this.getImageFromServer()}
            icon={{

                url: this.getImageFromServer() ,

                scaledSize: new window.google.maps.Size(37, 37)

            }}
      >


          </Marker>
    );
  }
}

const mapsDispatchToProps = (dispatch) =>
{
  return (
    {
      dateDynDispatch: (data) => dispatch(typeAction.loadDateDynTrajetAction(data)),

    }
  )
}

const mapsStateToProps = (state) =>
{
  //console.log("STATE LECTEUR ", state.lecteurTrajet)
  return ({
    loadTrajetLecteurInfo: state.lecteurTrajet,

  })
}

export default connect(mapsStateToProps, mapsDispatchToProps)(AnimatedMarkersCustum);
