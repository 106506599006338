import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";

import stylesNot from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

import { AuthContext } from '../../context/authentificationContext';

const useStyles = makeStyles(styles);
const useStylesNot = makeStyles(stylesNot);

export default function ExpirationPaiement() 
{

  const { signOut, signInFromLocal } = React.useContext(AuthContext);

  const handleLogOut = () => {
    signOut();
  }

  const handleUpdatePage = () => {
    signInFromLocal();
  }


  const classes = useStyles();
  const classesNot = useStylesNot();
  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>Choisissez le meilleur plan </h2>
          <h5 className={classes.description}>
            Vous avez un support illimité et des mises a jours pour chaque option.
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer justifyContent="center">
        
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Particulier ou PME </h6>
              <div className={classes.icon}>
                <Home className={classes.iconRose} />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                
              </h3>
              <p className={classes.cardDescription}>
                - Suivi de vehicule
              </p>
              <p className={classes.cardDescription}>
                - commande a distance
              </p>
              <p className={classes.cardDescription}>
                - Alerte
              </p>
              <p className={classes.cardDescription}>
                - Historique des trajets
              </p>
             <br/>
             <br/>
             <br/>
              
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Entreprise </h6>
              <div className={classes.icon}>
                <Business className={classes.iconRose} />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                
              </h3>
              <p className={classes.cardDescription}>
                - Suivi de vehicule
              </p>
              <p className={classes.cardDescription}>
                - commande a distance
              </p>
              <p className={classes.cardDescription}>
                - Alerte
              </p>
              <p className={classes.cardDescription}>
                - Historique des trajets
              </p>
              <p className={classes.cardDescription}>
                - Gestion carburant
              </p>
              <p className={classes.cardDescription}>
                - Gestion du park automobile
              </p>

             
            </CardBody>
          </Card>
        </GridItem>

      </GridContainer>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={12} className={classesNot.center}>
          <h2 className={classes.title}>Effectuer vos Paiements </h2>
          <h5 >
            Vous pouvez proceder au paiement par wave ou Orange money au +221 77 932 99 45.
          </h5>
        </GridItem>
      </GridContainer>
      
      <GridContainer justifyContent="center">
                  <GridItem xs={12} sm={12} md={12} className={classesNot.center}>
                    
                  <Button round color="info" onClick={() => {handleUpdatePage()}}>
                Actualiser
              </Button>
              <Button round color="info" onClick={() => {handleLogOut()}}>
                Deconnexion
              </Button>
                    </GridItem>
                    </GridContainer>
      
      
    </div>
  );
}
