import * as types from '../config/actionType'

// type command

export  const loadTypeCommandAction = () => {
    return {
        type: types.LOAD_TYPE_COMMAND
    }
}

export  const typeCommandActionLoaded = (typeCommand) => {
    return {
        type: types.TYPE_COMMAND_LOADED,
        typeCommand: typeCommand
    }
}