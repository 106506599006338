import * as types from '../config/actionType'

export  const modeMapAction = () => {
    return {
        type: types.MODE_MAP,
    }
}
export  const modeTrajetAction = () => {
    return {
        type: types.MODE_TRAJET,
    }
}

export  const modeCommandAction = () => {
    return {
        type: types.MODE_COMMAND,
    }
}

export  const modeParamAction = () => {
    return {
        type: types.MODE_PARAMATRE,
    }
}