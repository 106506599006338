import * as types from '../config/actionType'

export  const loadDateDynTrajetAction = (dateDyn) => {
    return {
        type: types.LOAD_DATE_DYN_TRAJET,
        dateDyn
        
    }
}

export  const dateDynActionLoaded = () => {
    return {
        type: types.DATE_DYN_TRAJET_LOADED,
    }
}
