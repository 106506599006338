import * as types from '../config/actionType'



export  const playTrajetAction = () => {
    return {
        type: types.PLAY_LECTEUR_DYN_TRAJET
        
    }
}


export  const pauseTrajetAction = () => {
    return {
        type: types.PAUSE_LECTEUR_DYN_TRAJET
        
    }
}




export  const prepReloadTrajetAction = () => {
    return {
        type: types.PREP_RELOAD_LECTEUR_DYN_TRAJET
        
    }
}





export  const reloadTrajetAction = () => {
    return {
        type: types.RELOAD_LECTEUR_DYN_TRAJET
        
    }
}


export  const stopTrajetAction = () => {
    return {
        type: types.STOP_LECTEUR_DYN_TRAJET
        
    }
}


export  const incSpeedTrajetAction = () => {
    return {
        type: types.INC_SPEED_LECTEUR_DYN_TRAJET
        
    }
}







