import axios from 'axios'
import { listeDeviceURL, itemDeviceURL, delDeviceURL, listeDeviceInutiliserURL } from 'config/api'
import { addDeviceURL, updateDeviceURL } from "config/api"
import { encryptStorage } from 'components/EncryptedStorage/encryptedStorage';

export const getListDevice = async () => 
{
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.get(listeDeviceURL , {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}

   
export const getListUnusedDevice = async () => 
{
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.get(listeDeviceInutiliserURL , {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}

export const getItemDevice = async (data) => 
{
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       
       return axios.get(itemDeviceURL + "/" + data.numeroDevice , {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}


export const addDeviceService = async (data) => 
{   
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.post(addDeviceURL , data.device, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
    
}



export const updateDeviceService = async (data) => 
{   
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.put(updateDeviceURL , data.device, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
}

export const deleteDeviceService = async(data) => 
{   
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.post(delDeviceURL , data.device, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
    
}