
import GridContainer from "components/Grid/GridContainer";
import React from "react";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Table from "components/Table/Table.js";
import * as typeAction from '../../action/typeCommandAction'
import stylesTable from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import { useState } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";

import * as notif from "components/Notification/notification"
import * as lancerCommandeService from '../../services/TypeCommandService';
import SweetAlert from "react-bootstrap-sweetalert";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";


const useStylesTable = makeStyles(stylesTable);
const useStyleAlert = makeStyles(stylesAlert)

const Commande = (props) => 
{
  const classesAlert = useStyleAlert();
  const [tableData, setTableData] = useState([]);
  const [alert, setAlert] = useState(null);

    const classesTable = useStylesTable();
  const tab = []

useEffect(() => {
  props.loadTypeCommandDispatch()
}, [])


const warningWithConfirmAndCancelMessage = (id) => {
  setAlert(
    <SweetAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Etes vous sure ?"
      onConfirm={() => handleButCommand(id)}
      onCancel={() => cancelCommand()}
      confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
      cancelBtnCssClass={classesAlert.button + " " + classesAlert.danger}
      confirmBtnText="Envoyer!"
      cancelBtnText="Annuler"
      showCancel
    >
      Le vehicule sera arreté !!!!.
    </SweetAlert>
  );
};

const cancelCommand = () => {
  setAlert(
    <SweetAlert
      danger
      style={{ display: "block", marginTop: "-100px" }}
      title="Annuler"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
    >
      La commande n'a pas ete envoye
    </SweetAlert>
  );
};
const hideAlert = () => {
  setAlert(null);
};


const handleButCommand = async (id) => 
{
  const title = "Connexion"
  let msg = "";

  if (id !== undefined)
  {
  lancerCommandeService.lancerCommand(props.loadVehiculSelectedRedux.numeroImmatriculation, id).then(
    (res) => 
    {

  
      
      if (res.data.operationReussie == true)
      {
        msg = 'Commande Envoye au Serveur'
        //console.log('Commande Envoye au Serveur')
        //notifyMessage('Connexion', 'Commande Envoye au Serveur')
        notif.showSuccessNotification(title, msg)
        
          
      }
      else
      {
        msg = 'Probleme Lors de l Envoi au Serveur';
      //  console.log(msg)
        //notifyMessage('Erreur de Connexion', msg)
        notif.showFailedNotification(title, msg)
      }
      //console.log(res.data.operationReussie)
      hideAlert();
    }
    
  ).catch((error) => 
  {
    msg = 'Probleme Lors de l Envoi au Serveur';
    notif.showFailedNotification(title, msg)
  })
  }
  else
  {
    msg = 'Probleme Lors de l Envoi au Serveur';
   // console.log("ID = UNDEFINED")
  }
}

const buttonValiderCommand = (id) => 
{
  const tabRound = [
    { color: "info",  onClick: warningWithConfirmAndCancelMessage}
    
].map((prop, key) => {
  return (
    <Button
      round
      color={prop.color}
      className={classesTable.actionButton + " " + classesTable.actionButtonRound}
      key={key}
      onClick={() => {prop.onClick(id)}}

    >
      Valider
    </Button>
  );
});
return tabRound
}

useEffect(() => {
  

  //const tab = props.loadTypeCommand.maps
 // setTableData(props.loadTypeCommand)

 const tabLoad =  props.loadTypeCommand;

 // console.log('***** Tab Load *********', tabLoad)
  let table = [];
  let cnt = 0;
  if (tabLoad !== undefined)
  {
    table = tabLoad.map((row) => 
   {
     cnt++;
    
     
     return(
       [
         cnt, row.label, props.loadVehiculSelectedRedux.marque + " " + props.loadVehiculSelectedRedux.modele + " " + props.loadVehiculSelectedRedux.numeroImmatriculation, props.loadVehiculSelectedRedux.enMarche === true ? "ALLUMER" : "ETEINT", buttonValiderCommand(row.identifiantCommTermi)
         //row.email,  row.nom + " " + row.prenom, row.clientNom, role, roundButtons(row.id)
       ]
     )
      
   }
   )
   setTableData(table)
  }
 }, [props.loadTypeCommand, props.loadVehiculSelectedRedux])

 const handleRefresh = () =>
 {
   
 }

    return (
        <GridContainer>
      {alert}
      <GridItem xs={12}>
      <div className="d-flex justify-content-end">
          <Button className="mr-2" color="info" onClick={()=>handleRefresh()} disabled={false}>
             Refresh List
          </Button>
          
          
        </div>
        <Card>
          <CardHeader /*color="info"*/ icon>
            <CardIcon /*color="info"*/>
              <Assignment />
            </CardIcon>
            <h4 className={classesTable.cardIconTitle}>client</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={[
                "#",
                "Commande",
                "Vehicule",
                "Etat vehicule",
                "Action",
              ]}
              tableData={tableData}
              customCellClasses={[classesTable.center, classesTable.right, classesTable.right]}
              customClassesForCells={[0, 4, 5]}
              customHeadCellClasses={[
                classesTable.center,
                classesTable.right,
                classesTable.right
              ]}
              customHeadClassesForCells={[0, 4, 5]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    )

}


const mapsStateToProps = (state) =>
{
  return(
    {
      loadTypeCommand: state.typeCommand,
      loadVehiculSelectedRedux: state.vehicules.vehiculeSelected
      //getVehiculeStateToProps: state.vehiculeItem
    }
  )
  
}

const mapsDispatchToProps = (dispatch) =>
{
  
  return {
    loadTypeCommandDispatch: () => dispatch(typeAction.loadTypeCommandAction())
  }
}



export default connect(mapsStateToProps, mapsDispatchToProps)(Commande)