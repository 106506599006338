import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
//import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

//import styles from "assets/jss/material-dashboard-pro-react/views/gridSystemStyle.js";
import Button from "components/CustomButtons/Button.js";
import PerfectScrollbar from "react-perfect-scrollbar";
import stylesButton from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { connect } from "react-redux";
import { loadVehiculeAction, loadVehiculeSelectedAction} from "action/vehiculeAction";
import { useEffect } from "react";
import { useState } from "react";


const useStylesButton = makeStyles(stylesButton);

const ListVehicule = (props) => 
{
    const {loadVehiculeFromSaga, vehiculeLoadDispatch} = props;
    
    const [tabVehicule, setTabVehicule] = useState([]);

    useEffect(() => {
        const data = {
            "identifiantClient": 14
          }
          vehiculeLoadDispatch(data)
    }, [])

    useEffect(() => {
        setTabVehicule(loadVehiculeFromSaga.vehicules);
    }, [loadVehiculeFromSaga])


    const handleButVehiculeClicked = (vehicule) => 
    {
        
        props.vehiculeSelectLoadDispatch(vehicule)
    }

   

    const loopVehicule = () => {

        
        const vehiculeMap = tabVehicule.map((vehicule) => {
            return (
                <Button key={vehicule.numeroImmatriculation} color="primary" round className={classesButton.marginRight} onClick={() => handleButVehiculeClicked(vehicule)}>
                            {vehicule.numeroImmatriculation}
                </Button>
            )
        })
        
        return (vehiculeMap)
    }

    const classesButton = useStylesButton();
    

    return (
        <GridItem xs={11} sm={3}>
            <Card>
                <CardBody style={ style.cardBody}>
                
                <PerfectScrollbar
                    className="header"
                    options={{ suppressScrollX: true, maxScrollbarLength: 100}}
                    //onScrollX={event => this.handleScrollLeft(event)}
                    >
                    <div style={style.button}>
                        {loopVehicule()}
                    </div>
                    </PerfectScrollbar>
                </CardBody>
            </Card>
         </GridItem>
    )
}

const style = {
    imageVehicule: {
        position: "relative",
        width: "100%",
        height: "auto",
        verticalAlign: "top",
        background: "hsl(0, 0%, 98%)"
      },
      cardBody: {
        //height: 230
        //color: "blue"
      },
      button: {
        height: 600,
        verticalAlign: "center"
        //color: "blue"
      }
}
const mapStateToProps = (state) => {
    
    return (
      {
        loadVehiculeFromSaga : state.vehicules
      }
    )
  }
  
  const mapsDispatchToProps = dispatch => {
    return (
      {
        vehiculeLoadDispatch: (data) => {dispatch(loadVehiculeAction(data))},
        vehiculeSelectLoadDispatch: (data) => {dispatch(loadVehiculeSelectedAction(data))}
    })
  }

export default connect(mapStateToProps, mapsDispatchToProps)(ListVehicule);