import * as types from '../config/actionType'



export const loadClientRequestAction = () => {
    return (
        {
            type: types.LOAD_CLIENT_REQ
        }
    )
}

export const loadClientErrorAction = (error) => {
    return (
        {
            type: types.LOAD_CLIENT_ERROR,
            error
        }
    )
}

export const loadClientFinishAction = () => {
    return (
        {
            type: types.LOAD_CLIENT_FINISH
        }
    )
}



export const loadClientAction = () => {
    return (
        {
            type: types.LOAD_CLIENTS
        }
    )
}


export const clientLoadedAction = (clients) => {
    return (
        {
            type: types.CLIENTS_LOADED,
            clients: clients
        }
    )
}

export const deleteClientAction = (client) => {
   
    return (
        {
            type: types.DELETE_CLIENTS,
            client
        }
    )
}


export const addClientAction = (client) => {
    
    return (
        {
            type: types.ADD_CLIENTS,
            client
        }
    )
}



export const upadateClientAction = (client) => {
    
    return (
        {
            type: types.UPDATE_CLIENTS,
            client
        }
    )
}