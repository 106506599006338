import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/gridSystemStyle.js";
import { useState } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";

const useStyles = makeStyles(styles);


const InfoVehicule = (props) => {

const [vehicule, setVehicule] = useState({
    anneeVehicule: "",
    marque: "",
    modele: "",
    nombreChevaux: "",
    nombreKilometrage: "",
    numeroImmatriculation: "",
    photoVehicule: "",
    typeVehicule: ""
})

const getImage = () => 
{
    const motoImg = require("../../assets/img/vehiculeImage/moto.png")
    const autoImg = require("../../assets/img/vehiculeImage/auto.png")

    if (vehicule.numeroImmatriculation !== "")
    {
        //console.log(vehicule.photoVehicule)
        if ((vehicule.photoVehicule === "") || (vehicule.photoVehicule === null))
        {
            return (
            <img
                            src={vehicule.typeVehicule === "Voiture" ? autoImg : motoImg}
                            width={250}
                            height={200}
                            style={styles.imageVehicule}
            />
            )
        }
        else
        {
            return (
                <img
                                src={'https://www.sentracking.com/photmat/' + vehicule.photoVehicule + ".png"}
                                width={250}
                                height={200}
                                style={styles.imageVehicule}
                />
                )
        }
    }
    else
    {
        return (
            <div style={{ width:250, height:200}}>

            </div>
        )
    }
}
    


useEffect(() => {
    setVehicule(props.loadVehiculSelectedRedux)
}, [props.loadVehiculSelectedRedux])


return (
    <GridContainer>
            <GridItem xs={12} sm={4}>
                <Card>
                    <CardBody style={{ textAlign: "center" }}>
                    {getImage()}
            </CardBody>
            </Card>
            </GridItem>
            <GridItem xs={12} sm={4}>
            <Card>
                <CardBody style={ style.cardBody}>
                    <div style={style.cardBody}>
                        <h4> Information Générale </h4>
                        <b> Marque :</b> {vehicule.marque} <br/>
                        <b> Model :</b> {vehicule.modele} <br/>
                        <b> Type :</b> {vehicule.typeVehicule} <br/>
                        <b> Année :</b> {vehicule.anneeVehicule} <br/>
                        <b> Imatriculation :</b> {vehicule.numeroImmatriculation} <br/>
                    </div>
                </CardBody>
            </Card>
            </GridItem>
            <GridItem xs={12} sm={4}>
            <Card>
            <CardBody style={ style.cardBody}>
                    <div style={style.cardBody}>
                        <h4> Information Générale </h4>
                        <b> Kilometrage :</b> {vehicule.nombreKilometrage} <br/>
                        <b> Puissance Fiscale :</b> {vehicule.nombreChevaux} <br/>
                        <b> Couleur :</b> {} <br/>
                        <b> Carburant :</b> {} <br/>
                        
                    </div>
                </CardBody>
            </Card>
        </GridItem>
    </GridContainer>
    )
}

const style = {
    imageVehicule: {
        position: "relative",
        width: "100%",
        height: "auto",
        verticalAlign: "top",
        background: "hsl(0, 0%, 98%)"
      },
      cardBody: {
        height: 230
        //color: "blue"
      }
}

const mapStateToProps = (state) => {
    return ({
        loadVehiculSelectedRedux: state.vehicules.vehiculeSelected
    }
    )

}

export default connect(mapStateToProps)(InfoVehicule);