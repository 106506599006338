
import axios from 'axios'
import {  authentificationURL } from '../config/api'

export const getAuthentificationData = (username, password) => 
{
    const data = JSON.stringify({
        username,
        password
        })

       

    return(axios.post(authentificationURL, data, {
            headers: {
                'Accept' : 'application/json',
                'Content-Type': 'application/json'
            }
        })
    )
}


