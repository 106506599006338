import { CLIENTS_LOADED, LOAD_CLIENT_REQ, LOAD_CLIENT_FINISH, LOAD_CLIENT_ERROR } from "../config/actionType";


const initialState = {
    loading: false,
    clients: [],
    error: ""
};

export const clientReducer = (state = initialState, action) => {
    switch (action.type)
    {
        case CLIENTS_LOADED:
            return (
                {
                    ...state,
                   clients: action.clients,
                }
            )
            case LOAD_CLIENT_REQ:
            return (
                {
                   ...state,
                   loading: true,
                   error: ""
                }
            )
            case LOAD_CLIENT_ERROR:
                return (
                    {
                       ...state,
                       error: "Erreur requete"
                    }
                )
                case LOAD_CLIENT_FINISH:
                return (
                    {
                       ...state,
                       loading: false,
                    }
                )

        default:
            return state
    }
}
