import axios from 'axios'
import { listeUserURL, itemUserURL, delUserURL } from 'config/api'
import { addUserURL, updateUserURL } from "config/api"

import { encryptStorage } from 'components/EncryptedStorage/encryptedStorage';

    
export const getListUserByCustomer = async (data) => 
{
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.post(listeUserURL , data.client, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}

export const getItemUser = async (data) => 
{
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.get(itemUserURL + "/" + data.userId, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}


export const addUserService = async (data) => 
{   
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.post(addUserURL , data.user, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
    
}



export const updateUserService = async (data) => 
{   
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.put(updateUserURL , data.user, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
}

export const deleteUserService = async (data) => 
{   
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.post(delUserURL , data.user, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
    
}