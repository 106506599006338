import * as actionType from '../config/actionType'

const initialState = {
    markers: [],
    error: ""
};

export const mapReducer = (state = initialState, action) => {
    
    switch (action.type)
    {
        case actionType.MARKERS_LOADED_POSITION:
            return(
                {
                    ...state,
                    markers: action.markers,
                    error: ""
                }    
            )
            case actionType.MARKERS_ERROR:
                return(
                    {
                        ...state,
                        error: action.error
                    }    
                )

        default:
            return (state);
    }
}