import * as types from '../config/actionType'



export const loadDeviceRequestAction = () => {
    return (
        {
            type: types.LOAD_DEVICE_REQ
        }
    )
}

export const loadDeviceErrorAction = (error) => {
    return (
        {
            type: types.LOAD_DEVICE_ERROR,
            error
        }
    )
}

export const loadDeviceFinishAction = () => {
    return (
        {
            type: types.LOAD_DEVICE_FINISH
        }
    )
}



export const loadDeviceAction = () => {
    return (
        {
            type: types.LOAD_DEVICES
        }
    )
}


export const deviceLoadedAction = (devices) => {
    return (
        {
            type: types.DEVICES_LOADED,
            devices: devices
        }
    )
}

export const deleteDeviceAction = (device) => {
   
    return (
        {
            type: types.DELETE_DEVICES,
            device
        }
    )
}


export const addDeviceAction = (device) => {
    
    return (
        {
            type: types.ADD_DEVICES,
            device
        }
    )
}



export const upadateDeviceAction = (device) => {
   
    return (
        {
            type: types.UPDATE_DEVICES,
            device
        }
    )
}