import * as actionType from '../config/actionType'

const initialState = {
    compteur: 0,
    dateDyn: "",
    latitude: 14.73386810245853,
    longitude: -17.464810721576214,
    vitesse: 0
};

export const dateDynReducer = (state = initialState, action) => {
    
    switch (action.type)
    {
        case actionType.LOAD_DATE_DYN_TRAJET:
            return(
                action.dateDyn
            )

            case actionType.DATE_DYN_TRAJET_LOADED:
                
                return(
                    state.dateDyn
                )
        default:
            return (state);
    }
}