import axios from 'axios'
import { listeClientURL, itemClientURL, delClientURL } from 'config/api'
import { addClientURL, updateClientURL } from "config/api"

import { encryptStorage } from 'components/EncryptedStorage/encryptedStorage';




//const token = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ0YWZAdGFmLmNvbSIsImV4cCI6MTYzNzI2Mjk3NywiaWF0IjoxNjE5MjYyOTc3fQ.hwvG3HAleQIImfjJiEtG_L3I8bqf3a5jADqrs2nh1dQFLatkintzuKx2fo3yBe2LDCF3ENRsdL8qCx6JhEL5_g" 

//getToken()

export const getListClient = async () => 
{
       const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;

       return axios.get(listeClientURL , {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}

export const getItemClient = async (data) => 
{
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;

       return axios.get(itemClientURL + "/" + data.numeroclient , {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}


export const addClientService = async (data) => 
{   
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.post(addClientURL , data.client, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
    
}



export const updateClientService = async (data) => 
{   
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
   // console.log('url =' + updateClientURL + '    updateClientService', data)
       return axios.put(updateClientURL , data.client, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
}

export const deleteClientService = async (data) => 
{   
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.post(delClientURL , data.client, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
    
}