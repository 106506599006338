import * as types from '../config/actionType'

export  const loadMarkersAction = () => {
    return {
        type: types.LOAD_MARKER_POSITION,
        
    }
}

export  const markersActionLoaded = (markers) => {
    return {
        type: types.MARKERS_LOADED_POSITION,
        markers: markers
    }
}

export  const markersActionError = (error) => {
    return {
        type: types.MARKERS_ERROR,
        error: error
    }
}


export  const centerToMarkersAction = (position) => {
    return {
        type: types.CENTRALISE_TO_MARKER,
        position
    }
}

export  const centerToMarkersEndAction = () => {
    return {
        type: types.MARKER_CENTRALISED,
        
    }
}


/*
// type command

export  const loadTypeCommandAction = () => {
    return {
        type: types.LOAD_TYPE_COMMAND,
        id: id
    }
}

export  const typeCommandActionLoaded = (typeCommand) => {
    return {
        type: types.TYPE_COMMAND_LOADED,
        typeCommand: typeCommand
    }
}
*/