import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// material-ui icons
import Assignment from "@material-ui/icons/Assignment";


// core components
import GridContainer from "components/Grid/GridContainer.js";

import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Table from "components/Table/Table.js";

//import styles from "assets/jss/material-dashboard-pro-react/views/gridSystemStyle.js";
import Button from "components/CustomButtons/Button.js";
import PerfectScrollbar from "react-perfect-scrollbar";
import stylesButton from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { connect } from "react-redux";
import { loadVehiculeForCustomerAction, loadVehiculeSelectedAction} from "action/vehiculeAction";
import { useEffect } from "react";
import { useState } from "react";


import {  Search } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput";


import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import moment from "moment";


const useStylesButton = makeStyles(stylesButton);

const styles = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const ListVehiculeTab = (props) => 
{
    const {loadVehiculeFromSaga, vehiculeLoadDispatch} = props;
    const [searchData, setSearchData] = useState("");
    const [tabVehicule, setTabVehicule] = useState([]);
    const [tabVehiculeForTable, setTabVehiculeForTable] = useState([]);

    const classes = useStyles();

    useEffect(() => {
        
          vehiculeLoadDispatch()
    }, [])


    const handleSearch = (even) => 
    {
      const keyboard = even.target.value.toLowerCase();
      setSearchData(keyboard);

      
    }

    useEffect(() => 
    {
    const update = setInterval(() => {
      vehiculeLoadDispatch()
    }, 10000);
    return () => clearInterval(update);
    }, [])

    useEffect(() => 
    {

        setTabVehicule(loadVehiculeFromSaga.vehicules);
    }, [loadVehiculeFromSaga])

    useEffect(() => 
    {
      //console.log("tabVehicule", tabVehicule)
      let cnt = 0;
      const tabSearched = tabVehicule.filter(tab => tab.numeroImmatriculation.toLowerCase().includes(searchData) || tab.marque.toLowerCase().includes(searchData) || tab.modele.toLowerCase().includes(searchData))
      //console.log("tabSearched = " , tabSearched)

      const tabVehiculeTabFilter = tabSearched.map((vehicule) => {
      //  console.log("tabVehicule", vehicule)
//photoVehicule: "051a29ff407887446b86f3dbd6a58debc3d77c23"
    
        cnt++;
        return ([cnt, getImageStatus(vehicule.emetPosition, vehicule.enMarche), getImageFromServer("", vehicule.typeVehicule, vehicule.photoVehicule, ""), vehicule.marque, vehicule.modele, vehicule.numeroImmatriculation])
      }
      )
      setTabVehiculeForTable(tabVehiculeTabFilter)
    }, [tabVehicule, searchData])




 

const getImageFromServer = (date, type, filename, styleCus) => 
{
  const motoImg = require("../../assets/img/vehiculeImage/moto.png")
  const autoImg = require("../../assets/img/vehiculeImage/auto.png")
  let status;
  let imageName;
  let autoMoto = ""

    if (filename === null)
    {
      return (
        <img
        src={type === "Voiture" ? autoImg : motoImg}
        alt={'voiture'}
        style={{ width: 25, height: 25 }}
        
      /> 
        
        )
    }
    return (
    
        <img
        src={'https://www.sentracking.com/photmat/' + filename + ".png"}
        alt={'voiture'}
        style={{ width: 30, height: 30 }}
        
      /> 
    )
    
  
}

  const getImageStatus = (emetPos, status, styleCus) => 
    {
      const on = require("../../assets/img/etatvehicule/rondvert.png")
      const off = require("../../assets/img/etatvehicule/rondrouge.png")
      const disconect = require("../../assets/img/etatvehicule/rondcroix.png")
    

      if (emetPos === false)
      {
        return (
          
                <img
          src={disconect}
          alt={'Deconnecter'}
          style={{ width: 25, height: 25 }}
          
        /> 
          )
      }
      else
      {
        return (
          <img
          src={status === true ? on : off}
          alt={status === true ? "Allumer" : "Deconnecter"}
          style={{ width: 25, height: 25 }}
        /> 
       
        )
      }
    }

    const handleButVehiculeClicked = (vehicule) => 
    {
        
        props.vehiculeSelectLoadDispatch(vehicule)
    }

   /*

    const loopVehicule = () => {

        
        const vehiculeMap = tabVehicule.map((vehicule) => {
            return (
                <Button key={vehicule.numeroImmatriculation} color="primary" round className={classesButton.marginRight} onClick={() => handleButVehiculeClicked(vehicule)}>
                            {vehicule.numeroImmatriculation}
                </Button>
            )
        })
        
        return (vehiculeMap)
    }
*/
    const classesButton = useStylesButton();
    

    const handleClick = (mat) => 
    {
      const vehiculeInfo = loadVehiculeFromSaga.vehicules.filter((v) => v.numeroImmatriculation === mat);
      if (vehiculeInfo.length > 0)
      {
        props.vehiculeSelectLoadDispatch(vehiculeInfo[0])
      }
      

      //console.log("vehicule info = " , vehiculeInfo[0])
    }

    return (
      <GridContainer 
    
    direction="column"
    
    spacing={2}>
      <GridItem xs={12}>
      
        <Card>

        <CardHeader color="info" icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}> Liste des Vehicules </h4>
          </CardHeader>
          <CardBody>
          
          <GridContainer>
            <GridItem  xs={12} sm={5}>
            
            </GridItem>
            <GridItem  xs={12} sm={5}>
            </GridItem>
            
            <GridItem  xs={12} sm={2}>
              <CustomInput
                    labelText="Rechercher"
                    id="Rechercher"
                    formControlProps={{
                      fullWidth: false
                    }}
                    inputProps={{
                      onChange: (even)  => {handleSearch(even)}
                    }}
                    
                  />
            </GridItem>
          </GridContainer>

          <Table 
          maxHeight={200}
            hover={true}
              handleClick = {handleClick}
              indexCol = {5}
              tableHeaderColor="primary"
              tableHead={["#", "etat", "logo", "Marque ", "Model", "Plaque"]}
              tableData={tabVehiculeForTable}
              coloredColls={[3, 4, 5]}
              colorsColls={["primary", "primary", "primary"]}
            />
            
          </CardBody>
        </Card>
        
      </GridItem>
    </GridContainer>
        /*<GridItem xs={11} sm={3}>
            <Card>
                <CardBody style={ style.cardBody}>
                
                <PerfectScrollbar
                    className="header"
                    options={{ suppressScrollX: true, maxScrollbarLength: 100}}
                    //onScrollX={event => this.handleScrollLeft(event)}
                    >
                    <div style={style.button}>
                        {loopVehicule()}
                    </div>
                    </PerfectScrollbar>
                </CardBody>
            </Card>
         </GridItem>
         */
    )
}

const style = {
    imageVehicule: {
        position: "relative",
        width: "100%",
        height: "auto",
        verticalAlign: "top",
        background: "hsl(0, 0%, 98%)"
      },
      cardBody: {
        //height: 230
        //color: "blue"
      },
      button: {
        height: 600,
        verticalAlign: "center"
        //color: "blue"
      }
}
const mapStateToProps = (state) => {
    //console.log("STATE VEHICULE ",state.vehicules)
    return (
      {
        loadVehiculeFromSaga : state.vehicules
      }
    )
  }
  
  const mapsDispatchToProps = dispatch => {
    return (
      {
        vehiculeLoadDispatch: () => {dispatch(loadVehiculeForCustomerAction())},
        vehiculeSelectLoadDispatch: (data) => {dispatch(loadVehiculeSelectedAction(data))}
    })
  }

export default connect(mapStateToProps, mapsDispatchToProps)(ListVehiculeTab);