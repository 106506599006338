
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//import Checkbox from "@material-ui/core/Checkbox";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";

import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";


import * as notif from "components/Notification/notification"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";



import {  useHistory, useRouteMatch } from "react-router-dom";
import { loadClientAction, deleteClientAction} from "action/clientAction";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { Details } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput";
import { useNavigate } from "react-router-dom-v5-compat";


//import product1 from "assets/img/product1.jpg";

const useStyles = makeStyles(styles);
const useStyleAlert = makeStyles(stylesAlert)


const Client = (props) => 
{
  const actualURL = useRouteMatch();
  const [tableData, setTableData] = useState([]);
  const [tableDataShow, setTableDataShow] = useState([]);
  const history = useNavigate();
  const {loadClientFromSaga, clientLoadDispatch, clientDeleteDispatch} = props;
  const [alert, setAlert] = React.useState(null);
  const [deleteStatut, setDeleteStatut] = React.useState(false);
  const [inputRecherch, setInputRecherch] = useState('')

  const dispatchRefreshTable = useCallback(() => {
    clientLoadDispatch()
  }, [clientLoadDispatch])


  const loadCardFromSagaUnique = useCallback(() => {
    return (loadClientFromSaga)
  }, [loadClientFromSaga])


  useEffect(() => {
    dispatchRefreshTable()
  },[dispatchRefreshTable])


  useEffect(() => 
  {
    if (deleteStatut === true)
    {
      if (loadClientFromSaga.loading === false)
        {
          let msg;
            let title;
          if (loadClientFromSaga.error.length > 0)
          {
  
            title = "Echeque Supression client"
              msg = "La client n a pas ete suprime";
            notif.showFailedNotification(title, msg)
            
          }
          else
          {
            title = "Supression client"
              msg = "La client   a ete suprimer";
            notif.showSuccessNotification(title, msg)
            setAlert(
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Suprimer!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
              >
                Cette article a ete supprimer.
              </SweetAlert>
            );
          }
          setDeleteStatut(false);
        }
    }

  }, [deleteStatut, loadClientFromSaga])
  

  const handleRefresh = () => {
    clientLoadDispatch()
  }


  const successDelete = (id) => {
    
    const data = 
    {
      'numeroDeSerieclient' : id
    }

    clientDeleteDispatch(data);
    setDeleteStatut(true)
    /*
    
    */
  };
  const cancelDetele = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Annuler"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
      >
        Votre article n a pas ete supprimer
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };




/*
active: true
dateReceptionclient: "2020-11-11T00:00:00.000+00:00"
etatclient: "new"
identifiantclient: 8
numeroclient: "778188858"
numeroDeCommandeclient: "command"
numeroDeSerieclient: "444433"
*/

const warningWithConfirmAndCancelMessage = (id) => {
  setAlert(
    <SweetAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Etes vous sure ?"
      onConfirm={() => successDelete(id)}
      onCancel={() => cancelDetele()}
      confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
      cancelBtnCssClass={classesAlert.button + " " + classesAlert.danger}
      confirmBtnText="Suprimer!"
      cancelBtnText="Annuler"
      showCancel
    >
      Vous ne pourez pas recuperer cette article!
    </SweetAlert>
  );
};


const classes = useStyles();
const classesAlert = useStyleAlert();

const handleButDelete = (id) => 
{
  warningWithConfirmAndCancelMessage(id);
 // console.log("CLIKKKKKK DELETE " + id)
}

const handleButUpdate = (id) => {
 // console.log("CLIKKKKKK UPDATE " + id)

 console.log('URL => ' ,actualURL.url)
  history(`${actualURL.url}/${id}/edit`)

}

const handleButShowDetail = (id) => {
  //console.log("CLIKKKKKK DETAIL "  + id)
  history(`${actualURL.url}/${id}/detail`)
}



const handleRechercheTextbox = (e) => {
  //console.log("reche text "  + e.target.value)
  setInputRecherch(e.target.value)
  
}
 
useEffect(() => {
 console.log(inputRecherch)
}, [inputRecherch])

const roundButtons = (id) => 
{
  const tabRound = [
    { color: "info", icon: Details , onClick: handleButShowDetail},
    { color: "success", icon: Edit , onClick: handleButUpdate},
  { color: "danger", icon: Close, onClick: handleButDelete }
].map((prop, key) => {
  return (
    <Button
      round
      color={prop.color}
      className={classes.actionButton + " " + classes.actionButtonRound}
      key={key}
      onClick={() => {prop.onClick(id)}}

    >
      <prop.icon className={classes.icon} />
    </Button>
  );
});
return tabRound
}



useEffect(() => 
{
  

 const tabLoad = loadClientFromSaga.clients;
 let table = [];
 let cnt = 0;
 if (tabLoad !== undefined)
 {
   table = tabLoad.map((row) => 
  {
    cnt++;
   
    return(
      [
        cnt,
        row.numeroclient,  row.adresseClient, row.emailClient, row.nomClient, row.telephone1 , row.type, roundButtons(row.identifiantClient)
      ]
    )
     
  }
  )
  setTableData(table)
 }

}, [loadCardFromSagaUnique])


useEffect(() => {
  
  if (inputRecherch.length > 0)
  {
    const data = tableData.filter((table) => {
      return (
        (table[3].toLowerCase().includes(inputRecherch.toLowerCase()) || (table[4].toLowerCase().includes(inputRecherch.toLowerCase())) || (table[5].toLowerCase().includes(inputRecherch.toLowerCase())))
      )
    })
    console.log('datashow => ',data)
    setTableDataShow(data);
  }
  else
  {
    setTableDataShow(tableData)
  }
}, [tableData, inputRecherch])
 

  return (
    <GridContainer>
      {alert}
      <GridItem xs={12}>
      <div className="d-flex justify-content-end">
          <Button className="mr-2" color="info" onClick={()=>{handleRefresh()}} disabled={false}>
             Refresh List
          </Button>
          <Button className="mr-2" color="info" onClick={()=>{ history(`${actualURL.url}/new`) }} disabled={false}>
             Ajouter client
          </Button>
          
        </div>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>client</h4>
          </CardHeader>
          <CardBody>


          <GridContainer>
            <GridItem  xs={12} sm={5}>
            
            </GridItem>
            <GridItem  xs={12} sm={5}>
            
            </GridItem>
            
            <GridItem  xs={12} sm={2}>
              <CustomInput
                    labelText="Rechercher"
                    id="Rechercher"
                    onChange={() => {console.log('ssss')}}
                    formControlProps={{
                      fullWidth: false
                    }}
                    inputProps={{
                      onChange: (e) => {handleRechercheTextbox(e)}
                    }}
                  />
            </GridItem>
          </GridContainer>


            <Table
              tableHead={[
                "#",
                "numero",
                "Adresse",
                "Email",
                "Nom",
                "telephone",
                "type",
                "Action",
              ]}
              tableData={tableDataShow}
              customCellClasses={[classes.center, classes.right, classes.right]}
              customClassesForCells={[0, 4, 5]}
              customHeadCellClasses={[
                classes.center,
                classes.right,
                classes.right
              ]}
              customHeadClassesForCells={[0, 4, 5]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>

  )
}


const mapStateToProps = (state) => {
  return (
    {
      loadClientFromSaga : state.clients
    }
  )
}

const mapsDispatchToProps = dispatch => {
  return (
    {
      clientLoadDispatch: () => {dispatch(loadClientAction())},
      clientDeleteDispatch: (data) => {dispatch(deleteClientAction(data))},      
  })
}

export default connect(mapStateToProps, mapsDispatchToProps)(Client);