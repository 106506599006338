import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components

import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import stylesButton from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import * as typeAction from "../../action/modeCommandAction.js"
import { connect } from "react-redux";
import { constanceMode } from '../../constance/constance';



const useStylesButton = makeStyles(stylesButton);

const ModeCommand = (props) => 
{
    const classesButton = useStylesButton();
    
    return (
        <Card >
            <CardBody  style={{ textAlign: "center" }}>
                <GridItem >
                    <Button color={(props.loadModeSelected === constanceMode.MAP) ? "google" : "facebook"}  round  size="sm" className={classesButton.marginRight} style={{width:40}} onClick={() => {props.modeMapCommandDispatch()}}>
                        Map
                    </Button>
                    <Button color={(props.loadModeSelected === constanceMode.COMMAND) ? "google" : "facebook"}  round size="sm" className={classesButton.marginRight} style={{width:60}} onClick={() => {props.modeCommandCommandDispatch()}}>
                        Command
                    </Button>
                    <Button color={(props.loadModeSelected === constanceMode.TRAJET) ? "google" : "facebook"}  round size="sm" className={classesButton.marginRight} style={{width:60}} onClick={() => {props.modeTrajetCommandDispatch()}}>
                        Trajet
                    </Button>
                    <Button color={(props.loadModeSelected === constanceMode.PARAMETRE) ? "google" : "facebook"}  round size="sm"  className={classesButton.marginRight} style={{width:80}} onClick={() => {props.modeParamCommandDispatch()}}>
                        Parametre
                    </Button>
                </GridItem>  
            </CardBody>
        </Card>
    )
}


const mapsDispatchToProps = (dispatch) =>
{
  
  return {
    modeMapCommandDispatch: () => dispatch(typeAction.modeMapAction()),
    modeCommandCommandDispatch: () => dispatch(typeAction.modeCommandAction()),
    modeTrajetCommandDispatch: () => dispatch(typeAction.modeTrajetAction()),
    modeParamCommandDispatch: () => dispatch(typeAction.modeParamAction())
  }
}


const mapStateToProps = (state) => 
{
   //console.log('loadModeSelected ', state.modeCommand.mode)
    return ({
        loadModeSelected: state.modeCommand.mode
    }
    )

}

export default connect(mapStateToProps, mapsDispatchToProps)(ModeCommand);

