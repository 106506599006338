import * as actionType from '../config/actionType'

const initialState = {
    
    etatCommand: 0,      //1 = enclench     0 = fini 
    param:{

    }
}

export const mapActionReducer = (state = initialState, action) => {
    switch (action.type)
    {
        case actionType.CENTRALISE_TO_MARKER :
            return (
                {
                    ...state,
                    etatCommand : 1,
                    param: action.position
                }
            )
            case actionType.MARKER_CENTRALISED :
                return (
                    {
                        ...state,
                        etatCommand : 0,
                        param: {}
                    }
                )
                default:
            return (state);
    }

}