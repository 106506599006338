
import axios from 'axios'
import {  changePasswordURL } from '../config/api'
import { encryptStorage } from 'components/EncryptedStorage/encryptedStorage';



export const  changePassword = async (oldPassword, newPassword, comfirmNewpassord) => 
{
    const userInfo =  await encryptStorage.getItem('userInfo');
     const token = "Bearer " + userInfo.userToken;
     const user = userInfo.userName;
           
           const dataToSend = {
                userName: user, 
                currentPassword: oldPassword,
                newConfirmPassword: newPassword,
                newPassword: comfirmNewpassord
            }
           //console.log('[trajet service] = datatosend = ' , dataToSend)
            return axios.post(changePasswordURL, dataToSend , {
                headers: {
                    'Authorization': token,
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(res => res.data)
        

}


