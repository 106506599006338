import GridItem from "components/Grid/GridItem";
import React, { useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import stylesButton from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import { FormControl, FormLabel } from "@material-ui/core";
import stylesForm from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Datetime from "react-datetime";
import PlayCircleIcon from "@material-ui/icons/PlayCircleFilledRounded";
import PauseCircleIcon from "@material-ui/icons/Pause";
import ReplaIcon from "@material-ui/icons/Replay";
import { connect } from "react-redux";
import * as typeActionMarker from "../../../../action/mapsAction" 
//import PlayCircleIcon from '@mui/icons-material/PlayCircle';


const useStylesButton = makeStyles(stylesButton);
const useStylesForm = makeStyles(stylesForm);

const MapAction = (props) => {
    const classesButton = useStylesButton();
    const classesForm = useStylesForm();


    

    const handleButCenterClicked = () => {
        //console.log("loadMarkerSelected", props.loadMarkerSelected)
        if (props.loadMarkerSelected.latitude !== "")
        {
                props.centerMarkerDispatch({
                        latitude: props.loadMarkerSelected.latitude,
                        longitude: props.loadMarkerSelected.longitude
                })
        }
    }

    return (
        <Card>
                <CardBody>
                        <GridContainer>
                                <GridItem md={2}>
                                        <Card>
                                                <CardBody>
                                                        
                                                    <Button size={"sm"} color="facebook" round  onClick ={() => {handleButCenterClicked()}} >
                                                            <PlayCircleIcon /> Centrer
                                                    </Button>
                                                        
                                                </CardBody>
                                        </Card>
                                </GridItem>
                                <GridItem >
                                        <Card>
                                          <CardBody>
                                          <GridContainer direction="column" >
                                                                <GridItem>
                                                                        <GridContainer>
                                                                                <GridItem>
                                                                                        <span style={{fontWeight: 'bold'}}> Vitesse : </span>  {props.loadMarkerSelected.vitesseKM} Km/h
                                                                                </GridItem>
                                                                                
                                                                                
                                                                        </GridContainer>
                                                                </GridItem>
                                                                <GridItem>
                                                                
                                                                <GridContainer>
                                                                                <GridItem>
                                                                                        <span style={{fontWeight: 'bold'}}> Etat : </span>  {props.loadMarkerSelected.enMarche ? "Allumer" : "Eteint"}
                                                                                </GridItem>
                                                                                <GridItem>
                                                                                    
                                                                                </GridItem>
                                                                                
                                                                </GridContainer>
                                                                                
                                                                </GridItem>
                                                                
                                                        </GridContainer>
                                        </CardBody>
                                        </Card>
                                </GridItem>
                                
                        </GridContainer>
                 </CardBody>
        </Card>
        
    );
}

const mapsDispatchToProps = (dispatch) => {
        return (
                {
                  
                  centerMarkerDispatch: (data) => dispatch(typeActionMarker.centerToMarkersAction(data)),
                  
            
                })
}

const mapsStateToProps = (state) =>
{
        
        if (state.vehicules.vehiculeSelected.marque !== undefined)
        {
                const activeVehicule = state.mapMarkers.markers.filter((marker) => 
                {
                        return (marker.numeroImmatriculation === state.vehicules.vehiculeSelected.numeroImmatriculation)
                })
                if (activeVehicule.length > 0)
                {
                        //console.log("activeVehicule[0] = ", activeVehicule[0])
                        return ({
                                loadMarkerSelected: activeVehicule[0]
                        })
                }
        }
        return (
                 {
                        loadMarkerSelected: {latitude : "", longitude: "", marque: "", numeroImmatriculation: "", dateMiseAjourDevice: "", enMarche: false, photoVehicule: null, emetPosition: false, typeVehicule: "", vitesseKM: ""}
                 })

  
}


export default connect(mapsStateToProps, mapsDispatchToProps)(MapAction);