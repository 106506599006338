
import { constanceMode } from '../constance/constance';
import * as actionType from '../config/actionType'



const initialState = {
    mode: constanceMode.MAP
};

export const modeCommandReducer = (state = initialState, action) => {

    switch (action.type)
    {
        case actionType.MODE_MAP:
            return (
                {
                    mode: constanceMode.MAP
                }
            )

        case actionType.MODE_COMMAND:
            return (
            {
                mode: constanceMode.COMMAND
            }
             )

        case actionType.MODE_PARAMATRE:
            return (
            {
                mode: constanceMode.PARAMETRE
            }
            )
        case actionType.MODE_TRAJET:
            return (
            {
                mode: constanceMode.TRAJET
            }
            )
    
        default: 
        return (state);
    }
}