
import axios from 'axios'
import {  listeTypeCommandURL, lancerCommandURL } from 'config/api'
import { encryptStorage } from 'components/EncryptedStorage/encryptedStorage';


export const  getListTypeCommand = async () => 
{

    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
    return axios.get(listeTypeCommandURL, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(res => res.data)
}
 

export const lancerCommand = async (matricule, numCommande) => 
{
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
    const email =  await encryptStorage.getItem('userInfo').userName;
    const data = {matriculeVehicule: matricule, numeroCommande: numCommande, utilisateur: email}
    return axios.post(lancerCommandURL, data, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json'
        }
    })
}