
import {all} from 'redux-saga/effects'
import { clientSaga } from './clientSaga';
import { commandTypeCommandSaga } from './commandTypeCommandSaga';
import { deviceSaga } from './deviceSaga';
import { mapSaga } from './mapSaga';
import { paiementSaga } from './paiementSaga';
import { paramUserSaga } from './parametreSaga';
import {simCardSaga} from './simCardSaga'
import { trajetSaga } from './trajetSaga';
import { userSaga } from './userSaga';
import { vehiculeSaga } from './vehiculeSaga';

function* rootSaga(){
    yield all([
        simCardSaga(),
        clientSaga(),
        deviceSaga(),
        vehiculeSaga(),
        userSaga(),
        paiementSaga(),
        mapSaga(),
        commandTypeCommandSaga(),
        trajetSaga(),
        paramUserSaga()
    ])
}

export default rootSaga;