import {takeEvery, call, put, all} from 'redux-saga/effects'

import * as types from '../config/actionType'
import * as service from '../services/simCardService'
import * as actions from '../action/simCardAction'

function* loadSims()
{
    try {
        yield put(actions.loadSimCardRequestAction())
        const simCards = yield call(service.getListCarteSim)
        yield put(actions.simCardLoadedAction(simCards))

    } catch (error) 
    {
        
        yield put(actions.loadSimCardErrorAction(error))
        yield put(actions.simCardLoadedAction([]))
        
    }
    finally
    {
        yield put(actions.loadSimCardFinishAction())
    }
}



function* addSim(data)
{
    try {
        
        yield put(actions.loadSimCardRequestAction())
        yield call(service.addSimCardService, data)
        //yield put(actions.loadSimCardAction())

    } catch (error) 
    {
        yield put(actions.loadSimCardErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadSimCardFinishAction())
    }
}

function* updateSim(data)
{
    try {
        
        yield put(actions.loadSimCardRequestAction())
        yield call(service.updateSimCardService, data)
        //yield put(actions.loadSimCardAction())

    } catch (error) 
    {
        yield put(actions.loadSimCardErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadSimCardFinishAction())
    }
}


function* deleteSim(data)
{
    try {
        
        yield put(actions.loadSimCardRequestAction())
        yield call(service.deleteSimCardService, data)
        yield put(actions.loadSimCardAction())

    } catch (error) 
    {
        yield put(actions.loadSimCardErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadSimCardFinishAction())
    }
}


function* watchLoadSimsCard()
{
 yield takeEvery(types.LOAD_SIMS, loadSims)
}

function* watchAddSimsCard()
{
 yield takeEvery(types.ADD_SIMS, addSim)
}

function* watchUpdateSimsCard()
{
 yield takeEvery(types.UPDATE_SIMS, updateSim)
}

function* watchDeleteSimsCard()
{
 yield takeEvery(types.DELETE_SIMS, deleteSim)
}

export function* simCardSaga() 
{
    yield all ([
        watchLoadSimsCard(),
        watchAddSimsCard(),
        watchUpdateSimsCard(),
        watchDeleteSimsCard(),
    ])
}