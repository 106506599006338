import {takeEvery, call, put, all} from  'redux-saga/effects'

import * as actions from '../action/mapsAction'
import * as types from '../config/actionType'
import * as service from '../services/mapsService'


function* watchLoadMaps()
{
    yield takeEvery(types.LOAD_MARKER_POSITION, loadMaps);
}

function* loadMaps()
{
    try {
        const markers = yield call(service.getPositionVoiture)
        yield put(actions.markersActionLoaded(markers))
    } catch (e) {
       // yield console.log(e)
        yield put(actions.markersActionError(e))
    } 
}

export function* mapSaga() {
    yield all([
        watchLoadMaps()
    ])
}