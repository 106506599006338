import {takeEvery, call, put, all} from 'redux-saga/effects'

import * as types from '../config/actionType'
import * as service from '../services/clientService'
import * as actions from '../action/clientAction'

function* loadClients()
{
    try {
        yield put(actions.loadClientRequestAction())
        const Clients = yield call(service.getListClient)
        yield put(actions.clientLoadedAction(Clients))

    } catch (error) 
    {
        yield put(actions.loadClientErrorAction(error))
        yield put(actions.clientLoadedAction([]))
        
    }
    finally
    {
        yield put(actions.loadClientFinishAction())
    }
}



function* addClient(data)
{
    try {
        
        yield put(actions.loadClientRequestAction())
        yield call(service.addClientService, data)
        //yield put(actions.loadClientAction())

    } catch (error) 
    {
        yield put(actions.loadClientErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadClientFinishAction())
    }
}

function* updateClient(data)
{
    try {
        
        yield put(actions.loadClientRequestAction())
        yield call(service.updateClientService, data)
        //yield put(actions.loadClientAction())

    } catch (error) 
    {
        yield put(actions.loadClientErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadClientFinishAction())
    }
}


function* deleteClient(data)
{
    try {
        
        yield put(actions.loadClientRequestAction())
        yield call(service.deleteClientService, data)
        yield put(actions.loadClientAction())

    } catch (error) 
    {
        yield put(actions.loadClientErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadClientFinishAction())
    }
}


function* watchLoadClients()
{
 yield takeEvery(types.LOAD_CLIENTS, loadClients)
}

function* watchAddClients()
{
 yield takeEvery(types.ADD_CLIENTS, addClient)
}

function* watchUpdateClients()
{
 yield takeEvery(types.UPDATE_CLIENTS, updateClient)
}

function* watchDeleteClients()
{
 yield takeEvery(types.DELETE_CLIENTS, deleteClient)
}

export function* clientSaga() 
{
    yield all ([
        watchLoadClients(),
        watchAddClients(),
        watchUpdateClients(),
        watchDeleteClients(),
    ])
}