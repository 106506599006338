import axios from 'axios'
import { listeCarteSimURL, itemCarteSimURL, delCarteSimURL, listeCarteSimUnusedURL } from 'config/api'
import { addCarteSimURL, updateCarteSimURL } from "config/api"

import { encryptStorage } from 'components/EncryptedStorage/encryptedStorage';

    
export const getListCarteSim = async () => 
{
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;

       return axios.get(listeCarteSimURL , {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}

export const getListUnusedCarteSim = async () => 
{
       const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;

       return axios.get(listeCarteSimUnusedURL , {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}


export const getItemCarteSim = async (data) => 
{
       const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;

       return axios.post(itemCarteSimURL , data, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
}


export const addSimCardService = async (data) => 
{   
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.post(addCarteSimURL , data.simCard, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
    
}



export const updateSimCardService = async (data) => 
{   
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
    
       return axios.put(updateCarteSimURL , data.simCard, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
}

export const deleteSimCardService = async (data) => 
{   
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
       return axios.post(delCarteSimURL , data.simCard, {
        headers: {
            'Authorization': token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            
        }
    }).then(res => res.data)
    
    
}