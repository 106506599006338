import {takeEvery, call, put, all} from  'redux-saga/effects'

import * as types from '../config/actionType'
import * as service from '../services/TypeCommandService'
import * as actions from '../action/typeCommandAction'

function* watchLoadListTypeCommand()
{
    yield takeEvery(types.LOAD_TYPE_COMMAND, loadListTypeCommand);
}

function* loadListTypeCommand()
{
    try {
        const typeCommands = yield call(service.getListTypeCommand)
        yield put(actions.typeCommandActionLoaded(typeCommands))
    } catch (e) {
       // console.log(e)

    } 
}

export function* commandTypeCommandSaga() {
    yield all([
        watchLoadListTypeCommand()
    ])
}