import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import stylesButton from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import stylesForm from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Datetime from "react-datetime";
import PlayCircleIcon from "@material-ui/icons/PlayCircleFilledRounded";
import PauseCircleIcon from "@material-ui/icons/Pause";
import ReplaIcon from "@material-ui/icons/Replay";
import { connect } from "react-redux";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";


import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { createSelector } from "reselect";


import { loadParamForCurrentUserAction, updateParamCurrentUserForCustomerAction } from "../../../action/paramUserAction";
import * as notif from "components/Notification/notification"


//import PlayCircleIcon from '@mui/icons-material/PlayCircle';


const useStylesButton = makeStyles(stylesButton);
const useStylesForm = makeStyles(stylesForm);
const useStylesExtendedForm = makeStyles(styles);

const ParametreAction = (props) => {
    
    const [tabDataParametre, setTabDataParametre] = useState([])
    const [openNotif, setOpenNotif] = useState(false)
    

  const classes = useStylesExtendedForm();

    const handleButSaved = () => {
        if (props.loadVehiculSelectedRedux.numeroImmatriculation !== undefined)
        {
            const data = {
                "matricule": props.loadVehiculSelectedRedux.numeroImmatriculation,
                "parametres": tabDataParametre
            }
            //console.log("data before send = ", data)
            setOpenNotif(true)
            props.updateParamDispatch(data);
        }
    }

    

    const handleCheck = (event) => 
    {
    const copyTabParam = [...tabDataParametre];
    const idCol = copyTabParam.findIndex(obj => obj.codeParametre === event.target.value);
    copyTabParam[idCol].valeur = event.target.checked === true ? "true" : "false"

    //console.log("tabDataParametre", tabDataParametre)
    //console.log("copyTabParam", copyTabParam)

    setTabDataParametre(copyTabParam);
    
    }

    useEffect(() => {
        props.loadParamDispatch();
        actionerParametre();
    }, [])


    const actionerParametre = () => {
        const propsParam = props.loadParamUserFromSaga;

        if (props.loadVehiculSelectedRedux.numeroImmatriculation !== undefined)
        {
            if (propsParam !== undefined)
            {
                if (propsParam.param !== undefined)
                {
                    const vehiculeSelect = props.loadVehiculSelectedRedux.numeroImmatriculation;
                    const data = propsParam.param.filter(data => data.matricule === vehiculeSelect)
                    if (data.length > 0)
                    {
                        //g("data = ", data[0])
                        //console.log("dataparametres = ", data[0].parametres)
                        setTabDataParametre(data[0].parametres);
                    }
                
                }
            }
        }
    }

    useEffect(() => {
        actionerParametre();
        
        //console.log("loadVehiculSelectedRedux = ", props.loadVehiculSelectedRedux)
    }, [props.loadVehiculSelectedRedux])



useEffect(()=> {

    if (openNotif === true)
    {
      if (props.loadParamUserFromSaga.loading === false)
      {
        let msg;
          let title;
          
        if (props.loadParamUserFromSaga.isError === true)
        {
          
            title = "Echeque Mise a jour parametre"
            msg = "Mise a jours parametre echoué";
          
         
          notif.showFailedNotification(title, msg)
          
        }
        else
            {
             
                title = "Mise a jour"
                msg = "La liste des parametres a ete mis à jour ";
              
              notif.showSuccessNotification(title, msg)
            }
        
        setOpenNotif(false)
    }
  }
    
  }, [props.loadParamUserFromSaga])

    const ShowParamJSX = () => {
        const jsxParam = tabDataParametre.map((param) => {
            
            return (
                <GridItem key={param.codeParametre}>
                    <div  className={classes.block} style={{paddingRight: 50, marginLeft: 20}}>
            
                        <FormControlLabel
                        //labelPlacement="start"
                        control={
                            <Switch
                            id={param.codeParametre + "_" + props.loadVehiculSelectedRedux.numeroImmatriculation}
                            //name={param.codeParametre}
                            
                            
                            checked={param.valeur === "true" ? true : false }
                            //defaultChecked={param.valeur === "true" ? true : false }
                            onChange={handleCheck}
                            value={param.codeParametre}
                            classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar
                            }}
                            />
                        }
                        classes={{
                            label: classes.label
                        }}
                        label={param.label}
                        /> 
        </div>
     </GridItem>
            )
        })

        return (
            <>
             {jsxParam}
            </>
           
        )
    }

   
    
    return (
        <Card>
                <CardBody>
                        <GridContainer>
                                <GridItem xs="auto">
                                        <Card>
                                                <CardBody>
                                                        
                                                        <FormControl >
                                                            <Button size={"sm"} color="facebook" round  onClick ={() => {handleButSaved()}} >
                                                                <PlayCircleIcon /> Valider
                                                            </Button>
                                                        </FormControl>
                                                </CardBody>
                                        </Card>
                                </GridItem>
                                
                                <GridItem xs={10} >
                                        <Card>
                                                <CardBody>
                                                        <GridContainer direction="column" >
                                                                <GridItem>
                                                                        <GridContainer>
                                                                            <ShowParamJSX />
                                                                               
                                                                        </GridContainer>
                                                                </GridItem>
                                                                
                                                        </GridContainer>
                                                </CardBody>  
                                        </Card>
                                </GridItem>
                        </GridContainer>
                 </CardBody>
        </Card>
        
    );
}


const getParamUser = (state) => state.paramUser
export const getParamUserState = createSelector(
  [ getParamUser ],
  (paramUser) => paramUser
)

const mapStateToProps = (state) => {
  // console.log('state param', state.paramUser)  
  return (
      {
        //loadParamUserFromSaga : state.paramUser
        loadParamUserFromSaga: getParamUserState(state),
        loadVehiculSelectedRedux: state.vehicules.vehiculeSelected,
      }
    )
  }

const mapsDispatchToProps = dispatch => {
    
    return (
      {
        loadParamDispatch: (data) => {dispatch(loadParamForCurrentUserAction())},
        updateParamDispatch: (data) => {dispatch(updateParamCurrentUserForCustomerAction(data))}
    })
  }


      export default connect(mapStateToProps, mapsDispatchToProps)(ParametreAction);