import * as types from '../config/actionType'



export const loadVehiculeRequestAction = () => {
    return (
        {
            type: types.LOAD_VEHICULE_REQ
        }
    )
}

export const loadVehiculeErrorAction = (error) => {
    return (
        {
            type: types.LOAD_VEHICULE_ERROR,
            error
        }
    )
}

export const loadVehiculeFinishAction = () => {
    return (
        {
            type: types.LOAD_VEHICULE_FINISH
        }
    )
}



export const loadVehiculeAction = (client) => {
    return (
        {
            type: types.LOAD_VEHICULES,
            client
        }
    )
}

export const loadVehiculeForCustomerAction = () => {
    return (
        {
            type: types.LOAD_VEHICULES_FOR_CUSTOMER,
        
        }
    )
}


export const vehiculeLoadedAction = (vehicules) => {
    return (
        {
            type: types.VEHICULES_LOADED,
            vehicules: vehicules
        }
    )
}


export const loadVehiculeSelectedAction = (vehicule) => {
    return (
        {
            type: types.LOAD_VEHICULE_SELECTED,
            vehiculeSelected: vehicule
        }
    )
}


export const deleteVehiculeAction = (vehicule) => {
    
    return (
        {
            type: types.DELETE_VEHICULES,
            vehicule
        }
    )
}


export const addVehiculeAction = (vehicule) => {
    
    return (
        {
            type: types.ADD_VEHICULES,
            vehicule
        }
    )
}



export const upadateVehiculeAction = (vehicule) => {
    
    return (
        {
            type: types.UPDATE_VEHICULES,
            vehicule
        }
    )
}