import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import stylesButton from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import Card from "components/Card/Card";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import * as notif from "components/Notification/notification"
import * as lancerCommandeService from '../../../services/TypeCommandService.js';
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import * as typeAction from '../../../action/typeCommandAction'


const useStylesButton = makeStyles(stylesButton);
const useStyleAlert = makeStyles(stylesAlert)

const CommandAction = (props) => {
    const classesButton = useStylesButton();
    const [alert, setAlert] = useState(null);
    const classesAlert = useStyleAlert();
const [buttonData,setButtonData] = useState(null)


const warningWithConfirmAndCancelMessage = (id) => {
        setAlert(
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Etes vous sure ?"
            onConfirm={() => handleButCommand(id)}
            onCancel={() => cancelCommand()}
            confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
            cancelBtnCssClass={classesAlert.button + " " + classesAlert.danger}
            confirmBtnText="Envoyer!"
            cancelBtnText="Annuler"
            showCancel
          >
            Le vehicule sera arreté !!!!.
          </SweetAlert>
        );
      };
      
      const cancelCommand = () => {
        setAlert(
          <SweetAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Annuler"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
          >
            La commande n'a pas ete envoye
          </SweetAlert>
        );
      };
      const hideAlert = () => {
        setAlert(null);
      };
      
      useEffect(() => {
        props.loadTypeCommandDispatch()
      }, [])

      useEffect(() => {
      //  console.log('butondata = ', buttonData)
      }, [buttonData])

      useEffect(() => {
  

        //const tab = props.loadTypeCommand.maps
       // setTableData(props.loadTypeCommand)
      
       const tabLoad =  props.loadTypeCommand;
      
        //console.log('***** Tab Load *********', tabLoad)
        let button;
        if (tabLoad !== undefined)
        {
                button = tabLoad.map((row) => 
         {
           
          // console.log("row = ", row)
           return(
                <GridItem md={2} key={row.identifiantCommTermi}>  
                
                        <Button color= {row.labellite === "couper_huile" ? "google" : row.labellite === "activer_huile" ? "success" : ""}
                        round className={classesButton.marginRight} onClick={() => {warningWithConfirmAndCancelMessage(row.identifiantCommTermi)}}>
                                {row.label}
                        </Button>
        
                </GridItem>
           )
            
         }
         )
         setButtonData(button)
        }
       }, [props.loadTypeCommand, props.loadVehiculSelectedRedux])
      

      const handleButCommand = async (id) => 
        {
        const title = "Connexion"
        let msg = "";
        
       // console.log(" matricule = ", props.loadVehiculSelectedRedux.numeroImmatriculation)
       // console.log(" commande = ", id)

        if (id !== undefined)
        {
        lancerCommandeService.lancerCommand(props.loadVehiculSelectedRedux.numeroImmatriculation, id).then(
        (res) => 
        {

        
        
        if (res.data.operationReussie == true)
        {
                msg = 'Commande Envoye au Serveur'
                //console.log('Commande Envoye au Serveur')
                //notifyMessage('Connexion', 'Commande Envoye au Serveur')
                notif.showSuccessNotification(title, msg)
                
                
        }
        else
        {
                msg = 'Probleme Lors de l Envoi au Serveur';
               //msg)
                //notifyMessage('Erreur de Connexion', msg)
                notif.showFailedNotification(title, msg)
        }
        //console.log(res.data.operationReussie)
        hideAlert();
        }
        
        ).catch((error) => 
        {
        msg = 'Probleme Lors de l Envoi au Serveur';
        notif.showFailedNotification(title, msg)
        })
        }
        else
        {
        msg = 'Probleme Lors de l Envoi au Serveur';
       // console.log("ID = UNDEFINED")
        }
        }
        //warningWithConfirmAndCancelMessage
      

    return (
        
        <Card >
                {alert}
        <GridContainer spacing={0} direction="row" justifyContent="center" alignItems="center">
                 <GridItem md={4}>  
                 
                </GridItem>
                        {buttonData}
                <GridItem md={4}>  
                </GridItem>
            

        </GridContainer>
        </Card>
    );
}

const mapsStateToProps = (state) =>
{
  return(
    {
      loadTypeCommand: state.typeCommand,
      loadVehiculSelectedRedux: state.vehicules.vehiculeSelected
      //getVehiculeStateToProps: state.vehiculeItem
    }
  )
  
}

const mapsDispatchToProps = (dispatch) =>
{
  
  return {
    loadTypeCommandDispatch: () => dispatch(typeAction.loadTypeCommandAction())
  }
}



export default connect(mapsStateToProps, mapsDispatchToProps)(CommandAction);