import * as types from '../config/actionType'



export const loadSimCardRequestAction = () => {
    return (
        {
            type: types.LOAD_SIM_REQ
        }
    )
}

export const loadSimCardErrorAction = (error) => {
    return (
        {
            type: types.LOAD_SIM_ERROR,
            error
        }
    )
}

export const loadSimCardFinishAction = () => {
    return (
        {
            type: types.LOAD_SIM_FINISH
        }
    )
}



export const loadSimCardAction = () => {
    return (
        {
            type: types.LOAD_SIMS
        }
    )
}


export const simCardLoadedAction = (simCards) => {
    return (
        {
            type: types.SIMS_LOADED,
            simCards: simCards
        }
    )
}

export const deleteSimCardAction = (simCard) => {

    return (
        {
            type: types.DELETE_SIMS,
            simCard
        }
    )
}


export const addSimCardAction = (simCard) => {
    
    return (
        {
            type: types.ADD_SIMS,
            simCard
        }
    )
}



export const upadateSimCardAction = (simCard) => {
    
    return (
        {
            type: types.UPDATE_SIMS,
            simCard
        }
    )
}