import {takeEvery, call, put, all} from 'redux-saga/effects'

import * as types from '../config/actionType'
import * as service from '../services/vehiculeService'
import * as actions from '../action/vehiculeAction'

function* loadVehicules(data)
{
    try {
        yield put(actions.loadVehiculeRequestAction())
        const vehicules = yield call(service.getListVehicule, data)
     
        yield put(actions.vehiculeLoadedAction(vehicules))

    } catch (error) 
    {
        //yield console.log('error ', error)
        yield put(actions.vehiculeLoadedAction([]))
        yield put(actions.loadVehiculeErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadVehiculeFinishAction())
    }
}


function* loadVehiculesForCustomer()
{
    try {
        yield put(actions.loadVehiculeRequestAction())
        const vehicules = yield call(service.getListVehiculeForCustomer)
     
        yield put(actions.vehiculeLoadedAction(vehicules))

    } catch (error) 
    {
       // yield console.log('error ', error)
        yield put(actions.vehiculeLoadedAction([]))
        yield put(actions.loadVehiculeErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadVehiculeFinishAction())
    }
}



function* addVehicule(data)
{
    try {
        
        yield put(actions.loadVehiculeRequestAction())
        yield call(service.addVehiculeService, data)
        //yield put(actions.loadVehiculeAction())

    } catch (error) 
    {
        yield put(actions.loadVehiculeErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadVehiculeFinishAction())
    }
}

function* updateVehicule(data)
{
    try {
        
        yield put(actions.loadVehiculeRequestAction())
        yield call(service.updateVehiculeService, data)
        //yield put(actions.loadVehiculeAction())

    } catch (error) 
    {
        yield put(actions.loadVehiculeErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadVehiculeFinishAction())
    }
}


function* deleteVehicule(data)
{
    try {
       
        yield put(actions.loadVehiculeRequestAction())
        yield call(service.deleteVehiculeService, data)
        yield put(actions.loadVehiculeAction())

    } catch (error) 
    {
        yield put(actions.loadVehiculeErrorAction(error))
        
    }
    finally
    {
        yield put(actions.loadVehiculeFinishAction())
    }
}


function* watchLoadVehicules()
{
 yield takeEvery(types.LOAD_VEHICULES, loadVehicules)
}


function* watchLoadVehiculesForCustomer()
{
 yield takeEvery(types.LOAD_VEHICULES_FOR_CUSTOMER, loadVehiculesForCustomer)
}


function* watchAddVehicules()
{
 yield takeEvery(types.ADD_VEHICULES, addVehicule)
}

function* watchUpdateVehicules()
{
 yield takeEvery(types.UPDATE_VEHICULES, updateVehicule)
}

function* watchDeleteVehicules()
{
 yield takeEvery(types.DELETE_VEHICULES, deleteVehicule)
}

export function* vehiculeSaga() 
{
    yield all ([
        watchLoadVehicules(),
        watchAddVehicules(),
        watchUpdateVehicules(),
        watchDeleteVehicules(),
        watchLoadVehiculesForCustomer(),
    ])
}