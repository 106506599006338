import * as actionType from '../config/actionType'

const initialState = [];

export const typeCommandReducer = (state = initialState, action) => {
    //console.log(state)
    switch (action.type)
    {
        case actionType.TYPE_COMMAND_LOADED:
            
            return(
                
                action.typeCommand
                
            )

        default:
            return (state);
    }
}